import moment from "moment";
import { Component } from "react";
import { toast } from "react-toastify";
import history from "../../../history";
import "./donationRequests.css";
import HeadingCompnonet from "./HeadingComponent";
const axios = require("axios");
const MaterialService = require("../../../services/MaterialService");
class DonationRequestsLists extends Component {
    constructor(props) {
        super(props);
        let searchParams = new URLSearchParams(this.props.location.search);
        let pageFromUrl = parseInt(searchParams.get("page")) || 1;
        this.state = {
            donationRequestsLists: [],
            currentPage: pageFromUrl || 1,
            paginate: {
                pages: 0,
                total: 0,
            },
        };
        console.log(props);
    }

    static getDerivedStateFromProps(props) {
        let searchParams = new URLSearchParams(props.location.search);
        let pageFromUrl = parseInt(searchParams.get("page")) || 1;
        return {
            currentPage: pageFromUrl || 1,
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.currentPage != prevState.currentPage) this.fetchData();
    };

    componentDidMount = () => {
        this.fetchData();
    };
    fetchData = () => {
        MaterialService.fetchData(
            `/v2/manager/donations/list?page=${this.state.currentPage}`
        ).then((res) => {
            if (res.status) {
                this.setState({
                    donationRequestsLists: res.data,
                    status: res.data.status,
                    paginate: res.meta,
                    downloadKey: res.meta.downloadKey,
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };
    onInputChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (value !== this.state.status) {
            this.setState({ [name]: value }, () => {
                this.updateStatus();
            });
        }
    };
    updateStatus = (id, status, callback = () => {}) => {
        MaterialService.postData("/v2/manager/donate-material/status-update", {
            id: id,
            status: status,
        }).then((res) => {
            callback();
        });
    };
    render() {
        return (
            <div className="main-container" id="donationrequests">
                <div>
                    <HeadingCompnonet
                        mainTitle="Donation Requests"
                        path="/material-manager"
                    />
                    <div className="banners-buttons">
                        <div
                            className="save-button"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                window.open(
                                    `${axios.defaults.baseURL}/materials/v2/download/donations/${this.state.downloadKey}`
                                );
                            }}
                        >
                            download
                        </div>
                    </div>
                    <div className="main-donation-requests-list-container">
                        <div className="mian-donation-requests-heading">
                            List of Donation Requests
                        </div>
                        <div className="col-md-12 row heading-container ">
                            <div className="col-md-1 padding-no">
                                Requested on
                            </div>
                            <div className="col-md-2 padding-no">Full Name</div>
                            <div className="col-md-3 padding-no">Email</div>
                            <div className="col-md-1 padding-no">Mobile</div>
                            <div className="col-md-1 padding-no">Type</div>
                            <div className="col-md-1 padding-no">Volunteer</div>
                            <div className="col-md-2 padding-no">Status</div>
                            <div className="col-md-1 padding-no">Edit</div>
                        </div>
                        <div className="volunteer-details-container">
                            {(() => {
                                if (
                                    this.state.donationRequestsLists.length > 0
                                ) {
                                    return (
                                        <>
                                            {this.state.donationRequestsLists.map(
                                                (lists) => {
                                                    return (
                                                        <div className="donation-requests-details-contents col-md-12 row">
                                                            <div className="col-md-1 padding-no">
                                                                {moment(
                                                                    lists.createdDate
                                                                ).format(
                                                                    "DD-MMM-YYYY"
                                                                )}
                                                            </div>
                                                            <div className="col-md-2 padding-no">
                                                                {lists.fullName}
                                                            </div>
                                                            <div className="col-md-3 padding-no">
                                                                {lists.email}
                                                            </div>
                                                            <div className="col-md-1 padding-no">
                                                                {lists.phone}
                                                            </div>
                                                            <div className="col-md-1 padding-no">
                                                                {lists.type}
                                                            </div>
                                                            <div
                                                                className={
                                                                    lists.volunteer
                                                                        ? "col-md-1 padding-no"
                                                                        : "col-md-1 padding-no no-volunteer"
                                                                }
                                                            >
                                                                {lists.volunteer ||
                                                                    "Assign volunteer"}
                                                            </div>
                                                            <select
                                                                className="col-md-2 select-dropdown  padding-no"
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    this.updateStatus(
                                                                        lists.id,
                                                                        event
                                                                            .target
                                                                            .value,
                                                                        this
                                                                            .fetchData
                                                                    );
                                                                }}
                                                                value={
                                                                    lists.status
                                                                }
                                                            >
                                                                <option value="0">
                                                                    Requested
                                                                </option>
                                                                <option value="1">
                                                                    Pickup
                                                                    scheduled
                                                                </option>
                                                                <option value="2">
                                                                    Pickup
                                                                    Complete
                                                                </option>
                                                                <option value="3">
                                                                    Received by
                                                                    Wishwa
                                                                </option>
                                                                <option value="4">
                                                                    Received by
                                                                    NGO
                                                                </option>
                                                                <option value="6">
                                                                    Donor Not
                                                                    Available
                                                                </option>
                                                                <option value="7">
                                                                    No Service
                                                                </option>
                                                                <option value="5">
                                                                    Closed
                                                                </option>
                                                            </select>
                                                            <div className="col-md-1 banner-edit-button  padding-no">
                                                                <i
                                                                    class="fas fa-pencil-alt"
                                                                    onClick={() => {
                                                                        history.push(
                                                                            `/material-manager/donation-request/detail/${lists.id}`
                                                                        );
                                                                    }}
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </>
                                    );
                                } else {
                                    return (
                                        <div
                                            className="alert alert-warning"
                                            style={{
                                                width: "max-content",
                                                height: "50px",
                                                textAlign: "center",
                                                margin: "auto",
                                            }}
                                        >
                                            No Data Found
                                        </div>
                                    );
                                }
                            })()}
                        </div>
                        <div className="pagination">
                            <i
                                className={
                                    "fas fa-chevron-left" +
                                    (this.state.currentPage == 1
                                        ? " inactive"
                                        : "")
                                }
                                onClick={() => {
                                    if (this.state.currentPage > 1) {
                                        history.push(
                                            this.props.location.pathname +
                                                "?page=" +
                                                (this.state.currentPage - 1)
                                        );
                                    }
                                }}
                            ></i>
                            <div>
                                {this.state.currentPage} /{" "}
                                {this.state.paginate.pages}
                            </div>
                            <i
                                className={
                                    "fas fa-chevron-right" +
                                    (this.state.currentPage <=
                                    this.state.paginate.pages
                                        ? ""
                                        : " inactive")
                                }
                                onClick={() => {
                                    if (
                                        this.state.currentPage <=
                                        this.state.paginate.pages
                                    ) {
                                        history.push(
                                            this.props.location.pathname +
                                                "?page=" +
                                                (this.state.currentPage + 1)
                                        );
                                    }
                                }}
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DonationRequestsLists;
